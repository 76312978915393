import React, { useState, useEffect, useRef } from 'react';
import PageTemplate from '../Template/PageTemplate';
import Footer from '../Footer/Footer';
import './BedanktPage.css';
import { useNavigate, useParams } from "react-router";
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import werkenbij from '../Api/werkenbij';
import Cookies from 'universal-cookie';
import LoadingScreenUsers from '../LoadingScreen/LoadingScreenUsers';
import image from '../Images/Group 8124.svg'

const BedanktPage = () => {
    return (
        <PageTemplate>
            <div>
                <div className='bodyContainer'>
                <div className='message'>
                    <div className='img'>
                        <img src={image} alt="image"/>
                    </div>
                    <div className='text ms-4'>
                        <h1>Dankjewel voor het indienen van je formulier</h1>
                        <p>Wat leuk dat we elkaar gaan spreken! Binnen één werkdag nemen we contact met je op om de voorkeursdatum te bevestigen en een tijd af te spreken. 
                        We kijken er naar uit om kennis te maken.</p>
                    </div>
                </div>
                </div>
                <Footer />
            </div>
        </PageTemplate >
    )
}

export default BedanktPage;