import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from "universal-cookie";
import HomePage from "./Components/HomePage/HomePage";
import Vacatures from "./Components/Vacatures/Vacatures";
import Login from "./Components/BackofficeWerkenbij/Login/Login";
import AllVacancies from "./Components/BackofficeWerkenbij/Pages/AllVacancies/AllVacancies";
import VacanciesAdd from "./Components/BackofficeWerkenbij/Pages/VacanciesAdd/VacanciesAdd";
import VacanciesEdit from "./Components/BackofficeWerkenbij/Pages/VacancyEdit/VacanciesEdit";
import Settings from "./Components/BackofficeWerkenbij/SettingsBackoffice/Settings";
import NotFound from "./Components/NotFound/NotFound";
import BedanktPage from "./Components/HomePage/BedanktPage";

function App() {
  return (
    <BrowserRouter>
    <ToastContainer/>
    <UnauthorizedEventListener />
      <Routes>
      <Route path="/vacatureInfo/:function_name" element={<HomePage />} />
      <Route path="/login" element={<Login />}></Route>
        <Route path="/" element={<Vacatures />}></Route>
        <Route path="/bedankt" element={<BedanktPage />}></Route>
        <Route path="/backoffice/instellingen" element={<Settings />}></Route>
        <Route path="/backoffice/vacature" element={<AllVacancies />}></Route>
        <Route path="/backoffice/vacature_nieuw" element={<VacanciesAdd />}></Route>
        <Route path="/backoffice/vacature_edit/:job_offer_id" element={<VacanciesEdit />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const UnauthorizedEventListener = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    const handleUnauthorized = () => {
      cookies.remove('token', { path: '/' });
      navigate('/login');
    };

    window.addEventListener('unauthorized-event', handleUnauthorized);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('unauthorized-event', handleUnauthorized);
    };
  }, []);

  return null; // This component does not render anything
};

export default App;