import React, { useState, useEffect } from 'react';
import "./Navbar.css";
import logo from "../Images/logo tech dog_group_met payoff.png";
import icoon from "../Images/Icon metro-phone.svg";
import IconButton from '../MainComponents/IconButton';
import { useNavigate } from 'react-router';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbarStyling w-100">
      <div className="container-fluid">
        <a className="navbar-brand cursorPointer" onClick={() => navigate("/")}>
          <img src={logo} className='LogoStyle mx-3' alt="logo" />
        </a>
        <button className="navbar-toggler" type="button" onClick={toggleDropdown}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse justify-content-end ${isOpen ? 'show' : ''}`} id="navbarNav">
          {!isSmallScreen && (
            <div className='w-100 d-flex justify-content-center'>
              {/* in comments until 'onze cultuur' and 'onze organisatie' are needed */}
              {/* <ul className="navbar-nav mb-2 mb-lg-0 w-75 justify-content-around me-4 ">
                <li className="nav-item">
                  <a className="nav-link active navItems cursorPointer" aria-current="page" onClick={() => navigate("/")}>Vacatures</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link navItems inActive">Onze cultuur</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link navItems inActive">Onze organisatie</a>
                </li>
                <li className="nav-item d-lg-none">
                  <IconButton sideIcon={"left"} icon={icoon} textButton={"KOM IN CONTACT"} phone={"+31 85 760 0729"} />
                </li>
              </ul> */}
            </div>
          )}
           <div className="d-none d-lg-block">
          <div className='d-flex align-items-center'>
              <a className="nav-link active navItems cursorPointer px-5" aria-current="page" onClick={() => navigate("/")}>Vacatures</a>
              <IconButton sideIcon={"left"} icon={icoon} textButton={"KOM IN CONTACT"} phone={"+31 85 760 0729"} />
            </div>
          </div>
        </div>
      </div>
      {isOpen && isSmallScreen && (
        <div className="dropdown-menu-custom py-0">
          <ul className="navbar-nav flex-column">
            <li className="nav-item border-bottom border-top">
              <a className="nav-link navItems" onClick={() => navigate("/")} >Vacatures</a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link navItems" href="#">Onze cultuur</a>
            </li>
            <li className="nav-item border-bottom border-top">
              <a className="nav-link navItems" href="#">Onze organisatie</a>
            </li> */}
            <li className="nav-item d-flex justify-content-center my-2">
              <IconButton sideIcon={"left"} icon={icoon} textButton={"KOM IN CONTACT"} phone={"+31 85 760 0729"} />
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
