import React, { useEffect, useState } from 'react';
import PageLayout from '../../PageLayout/PageLayout';
import werkenbij from '../../../Api/werkenbij';
import "./AllVacancies.css";
import { useNavigate, useLocation } from "react-router";
import ReactPaginate from "react-paginate";
import defaultpic from "../../../Images/Rectangle 30.png";
import Cookies from "universal-cookie";
import Loadingscreen from "../../../LoadingScreen/LoadingScreen";
import ToastSuccess from '../../../Toasts/ToastSuccess';
import ToastError from '../../../Toasts/ToastError';


const AllVacancies = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(true);
  const vacanciesPerPage = 9; // Number of vacancies to display per page

  // Parse the current page from the URL query parameters
  const query = new URLSearchParams(location.search);
  const initialPage = parseInt(query.get('page')) || 0;
  const [currentPage, setCurrentPage] = useState(initialPage);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchVacatures] = await Promise.all([
        werkenbij.get("/sp/allvacancies"),
      ]);
      setVacancies(fetchVacatures.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    navigate(`?page=${data.selected}`);
  };

  const removeVacature = async (jobOfferId) => {
    const isConfirmed = window.confirm("Weet je zeker dat je de vacature wilt verwijderen.");
    
    if (isConfirmed) {
      try {
        setLoading(true);
        // Fetch all related data based on the jobOfferId
        const [info] = await Promise.all([
          werkenbij.get('sp/allvacancies'),
        ]);
  
        const vacancies = info.data;
  
        // Find the specific job offer with the given jobOfferId
        const vacancy = vacancies.find(vacancy => vacancy.job_offer_id === jobOfferId);
  
        if (!vacancy) {
          ToastError('Vacature niet gevonden.');
          return;
        }
  
        // Now delete the job offer itself
        try {
          await werkenbij.delete(`/joboffers?job_offer_id=${jobOfferId}`);
        } catch (error) {
          console.error(`Error deleting job offer with ID ${jobOfferId}:`, error);
        }
  
        ToastSuccess('Vacature en alle bijbehorende data is verwijderd.');
        fetchAll();
      } catch (error) {
        ToastError('Er is iets fout gegaan bij het verwijderen van de vacature.');
      }
    }
  };

  const toggleVacatureStatus = async (jobOfferId, currentStatus) => {
    try {
      setLoading(true);
      const newStatus = currentStatus == 0 ? 1 : 0;

      await werkenbij.put(`/joboffers`, {
        job_offer_id: jobOfferId,
        offer_status: newStatus,
      });

      fetchAll();
    } catch (error) {
      alert('Er is iets fout gegaan bij het updaten van de vacature status.');
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  const offset = currentPage * vacanciesPerPage;
  const currentVacancies = vacancies.slice(offset, offset + vacanciesPerPage);

  if (loading) {
    return <Loadingscreen>laden</Loadingscreen>;
  }
  return (
    <PageLayout>
      <div className="d-flex flex-column allVacanciesList align-items-center">
        <div className='mb-3 w-100'>
          <h2 className='text-center'>Vacatures</h2>
        </div>
        {loading ? (
          <div className="d-flex flex-column jobOffersGridParent">Laden</div>
        ) : ( 
          <div className="d-flex flex-column jobOffersGridParent mb-5">
            {vacancies.length < 1 ? (
                <div>Geen resultaten gevonden</div>
              ) : (
              <>
                <div className="vacanciesGrid">
                  {currentVacancies.map((vacature, key) => {
console.log(vacature.work_hours)

                    return (
                      <div key={key} className="cardContainer cursorPointer" onClick={() => navigate(`/backoffice/vacature_edit/${vacature.job_offer_id}`)}>
                        <div className='VacancieCardBackoffice'>
                          <img src={vacature.job_offer_header_image || defaultpic} className='imgvacanciesCard mb-3' alt="Vacature" />
                          <div>
                            <p className='fw-bold mb-1'>{truncateText(vacature.function_name, 20)}</p>
                            <p className="functionDescription wordbreakAll">{truncateText(vacature.function_description, 30)}</p>
                          </div>
                          <div>
                            <p className='whiteBackground'>{vacature.department} (Werkgebied)</p>
                            <p className='whiteBackground'>{vacature.job_type} (Type)</p>
                            <p className='whiteBackground mb-3'>{vacature.work_hours} (Uren)</p>
                          </div>
                          <div>
                            <button 
                              className='DeleteButtonVacancie'
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents the parent click event from firing
                                removeVacature(vacature.job_offer_id);
                              }}>Verwijderen
                            </button>
                            <button 
                              className={`StatusButtonVacancie ${vacature.offer_status == 0 ? 'active' : 'inactive'}`}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents the parent click event from firing
                                toggleVacatureStatus(vacature.job_offer_id, vacature.offer_status);
                              }}>
                              {vacature.offer_status == 0 ? 'Actief' : 'Inactief'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
              )}
              <div>
                  <ReactPaginate
                    previousLabel={null}
                    nextLabel={null}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(vacancies.length / vacanciesPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeLinkClassName={"active-link"}
                  />
              </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default AllVacancies;
