import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import PageTemplate from '../Template/PageTemplate';
import Footer from '../Footer/Footer';
import "./HomePage.css";
import { useNavigate, useParams } from "react-router";
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Photo1 from "../Images/Rectangle 22.png";
import Photo2 from "../Images/Rectangle 25.png";
import Photo3 from "../Images/Group 31.png";
import Photo4 from "../Images/Rectangle 28.png";
import Photo5 from "../Images/Rectangle 30.png";
import Photo6 from "../Images/Group 32.png";
import Photo7 from "../Images/Rectangle 37.png";
import established from "../Images/established.png";
import Pin from "../Images/Group 29.png";
import Team from "../Images/Outline.png";
import check from "../Images/Icon akar-check.png";
import IconButton from '../MainComponents/IconButton';
import icoon from "../Images/Icon metro-phone.svg";
import mail from "../Images/email.png";
import arrowRight from "../Images/Icon ionic-ios-arrow-round-forward.png";
import werkenbij from '../Api/werkenbij';
import Cookies from 'universal-cookie';
import ToastSuccess from '../Toasts/ToastSuccess';
import ToastError from '../Toasts/ToastError';
import LoadingScreenUsers from '../LoadingScreen/LoadingScreenUsers';

const HomePage = () => {
  const location = useLocation();
  const [isWeOfferOpen, setWeOfferOpen] = useState(false);
  const [vacancy, setVacancy] = useState([]);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [videoWorkday, setVideoWorkday] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isExploreMoreOpen, setExploreMoreOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telNum, setTelNum] = useState("");
  const [email, setEmail] = useState("");
  const [contactReason, setContactReason] = useState("");
  const [isVertical, setIsVertical] = useState(false);
  const videoRef = useRef(null);
  const jobOfferIdFromState = location.state?.job_offer_id;
  const job_offer_id = parseInt(jobOfferIdFromState, 10);
  const fetchAll = async () => {
    try {
      setLoading(true);

      if (!job_offer_id || isNaN(job_offer_id)) {
        return navigate("/");
      }

      const [fetchVacatures] = await Promise.all([
        werkenbij.get(`sp/vacancypage?job_offer_id=${job_offer_id}&offer_status=0`),
      ]);
      console.log(fetchVacatures);
      setVacancy(fetchVacatures.data);
      if (fetchVacatures.data.length < 1) {
        navigate("/")
      }
      setContactReason(fetchVacatures.data.function_name);
      setLoading(false);
    } catch (error) {

      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  const fetchVideo = async () => {
    try {
      const [fetchWorkdayVideo] = await Promise.all([
        werkenbij.get(`/joboffersworkdayvideos?job_offer_id=${job_offer_id}`),
      ]);
      setVideoWorkday(fetchWorkdayVideo.data[0])
    } catch (error) {

      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };


  useEffect(() => {
    fetchAll();
    fetchVideo();
  }, [job_offer_id]);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleLoadedMetadata = () => {
      const { videoWidth, videoHeight } = videoElement;
      setIsVertical(videoHeight > videoWidth);
    };

    console.log(videoElement);

    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      return () => {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [job_offer_id]);



  const decodeJsonString = (jsonString) => {
    if (jsonString) {
      try {
        const decodedArray = JSON.parse(jsonString);
        return decodedArray;
      } catch (error) {
        console.error('Error parsing JSON string:', error);
        return null;
      }
    }
  };

  const toggleWeOffer = () => {
    if (isSmallScreen) setWeOfferOpen(!isWeOfferOpen);
  };

  const toggleExploreMore = () => {
    if (isSmallScreen) setExploreMoreOpen(!isExploreMoreOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const submitContactForm = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      const resMail = await werkenbij.post(`sendmail`, {
        email: email,
        first_name: firstName,
        last_name: lastName,
        phone_number: telNum,
        contact_reason: contactReason
      });

      navigate("/bedankt");
      setFirstName('');
      setLastName('');
      setEmail('');
      setContactReason('');
      setTelNum('');
    } catch (error) {
      error.response.data.retry_after_seconds ?
        ToastError(`Je moet nog ${error.response.data.retry_after_seconds} seconden wachten voordat je en nieuwe kan sturen`)
        :
        ToastError(`Er is iets fout gegaan bij het versturen van je formulier, vul de gegevens opnieuw in`)
        ;
    }
  }

  const truncateDescription = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength);
    }
    return text;
  };

  const experiences = decodeJsonString(vacancy.employee_experiences_array);
  const experiencesCount = experiences ? experiences.length : 0;

  // console.log(videoWorkday);

  if (loading) {
    return <LoadingScreenUsers>Loading...</LoadingScreenUsers>;
  }

  return (
    <PageTemplate>
      <div>
        <div className='imgOnlineMarketingParent'>
          <img src={Photo1} className='imgheaderVacature' alt="Online Marketing" />
          <div className="centered text-nowrap w-100">
            <span className='pageIntroTitle mb-0 wordWrapAll proxima-nova-semibold text-uppercase px-3'>{vacancy.function_name || ""}</span>
            <div className='pageIntroSubtitle fw-light d-flex flex-row justify-content-center'>
              <p className='mb-0'>{vacancy.work_hours} UUR</p>
              <p className='dividers d-flex align-self-center'>|</p>
              <p className='mb-0 text-uppercase'>{vacancy.department}</p>
              <p className='dividers d-flex align-self-center'>|</p>
              <p className='mb-0'>HENGELO</p>
            </div>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-center offerAndExploreParent'>
          <div className='offerAndExplore d-flex flex-row'>
            {vacancy.we_offer_array == null && vacancy.explore_more_array == null ?
              <div className='knowMore w-100 d-flex justify-content-center flex-column'>
                <div>
                  <p className='proxima-nova-bold text-center mb-0' style={{ fontSize: "50px" }}>Meer weten</p>
                  <p className='proxima-nova-bold text-center' style={{ fontSize: "50px" }}>over deze functie?</p>
                </div>
                <div className='d-flex justify-content-center'>
                  <IconButton sideIcon={"left"} icon={icoon} textButton={"KOM IN CONTACT"} mail={"sollicitatie@techdog.nl"} />
                </div>
              </div>
              :
              <>
                <div className='offerAndExploreInfo d-flex flex-column'>
                  {vacancy.we_offer_array?.length > 0 ?
                    <div className='weOffer'>
                      <h3 className='mb-3 text-nowrap proxima-nova-bold' style={{ fontSize: "25px" }} onClick={toggleWeOffer}>
                        Dit bieden wij:
                        {isSmallScreen && (
                          <span className="indicator">
                            <FontAwesomeIcon icon={isWeOfferOpen ? faCaretUp : faCaretDown} />
                          </span>
                        )}
                      </h3>
                      <div className={`accordion-content ${isSmallScreen && isWeOfferOpen ? 'open' : ''}`}>
                        {decodeJsonString(vacancy.we_offer_array)?.map((weOffer, key) => {
                          return (
                            <p key={key} className='fw-normal my-4 weOfferWordbreak'>{weOffer.title}</p>
                          )
                        })}
                      </div>
                      {!isSmallScreen && (
                        <div className='weOfferItemsScroll'>
                          {decodeJsonString(vacancy.we_offer_array)?.map((weOffer, key) => {
                            return (
                              <p key={key} className='fw-normal my-4 weOfferWordbreak'>{weOffer.title}</p>
                            )
                          })}
                        </div>
                      )}
                    </div>
                    : ""}
                  {vacancy.explore_more_array?.length > 0 ?
                    <div className='exploreMore'>
                      <h3 className='mb-3 text-nowrap proxima-nova-bold' style={{ fontSize: "25px" }} onClick={toggleExploreMore}>
                        Ontdek meer over:
                        {isSmallScreen && (
                          <span className="indicator">
                            <FontAwesomeIcon icon={isExploreMoreOpen ? faCaretUp : faCaretDown} />
                          </span>
                        )}
                      </h3>
                      <div className={`accordion-content ${isSmallScreen && isExploreMoreOpen ? 'open' : ''}`}>
                        {decodeJsonString(vacancy.explore_more_array)?.map((explore, key) => {
                          return (
                            <p key={key} className='fw-normal my-4 exploreMoreWordBreak'>{explore.title}</p>
                          )
                        })}
                      </div>
                      {!isSmallScreen && (
                        <div className='discoverMoreItemsScroll'>
                          {decodeJsonString(vacancy.explore_more_array)?.map((explore, key) => {
                            return (
                              <p key={key} className='fw-normal my-4 exploreMoreWordBreak'>{explore.title}</p>
                            )
                          })}
                        </div>
                      )}
                    </div>
                    : ""}
                </div>
                <div className='knowMore d-flex justify-content-center flex-column'>
                  <div>
                    <p className='fw-bold text-center mb-0' style={{ fontSize: "50px" }}>Meer weten</p>
                    <p className='fw-bold text-center' style={{ fontSize: "50px" }}>over deze functie?</p>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <IconButton sideIcon={"left"} icon={icoon} textButton={"KOM IN CONTACT"} phone={"+31 85 760 0729"} />
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        <div className='jobDescriptionHomepage d-flex flex-row urJob px-4 justify-content-between'>
          <div className='d-flex align-items-center'>
            <img src={vacancy.job_description_image || Photo2} className='imgUrJob ' alt="Your Job" />
          </div>
          <div className='urJobInfo py-4 px-4'>
            <h1 className='fw-bold' style={{ fontSize: "35px" }}>Dit is de baan!</h1>
            <p className='jobdesc'>{vacancy.job_description}</p>
          </div>
        </div>
        {vacancy.job_distribution_array?.length > 0 ?
          <div className='urFuncProcentage mb-4'>
            <div className='urFuncParent'>
              <p className='urFunc proxima-nova-bold mb-0'>Zo kan jouw functie eruit zien</p>
            </div>
            <div className='procentageJob'>
              {decodeJsonString(vacancy.job_distribution_array)?.reduce((result, job, key, array) => {
                const colors = ["#7db9e2", "#2283c5", "#176192", "#0d3d5e"];
                const item = (
                  <div key={key} className='d-flex flex-row mb-5'>
                    <div className='ProcentageCircle me-5 proxima-nova-bold' style={{ backgroundColor: colors[key % colors.length] }}>
                      {job.percentage}%
                    </div>
                    <p className='funcSubTitle d-flex align-items-center mb-0 proxima-nova-bold pe-3 whiteSpaceWrap' style={{ fontSize: "30px" }}>{job.title}</p>
                  </div>
                );
                if (key % 2 === 0) {
                  result.push([item]);
                } else {
                  result[result.length - 1].push(item);
                }
                return result;
              }, []).map((group, index) => (
                <div key={index}>
                  {group}
                </div>
              ))}
            </div>
          </div>
          :
          ""}
        {/* Video Werkdag */}
        {/*
        <div className='dayAs d-flex flex-row justify-content-between'>
          {videoWorkday?.workday_video_path ?
            <video className={`imgVideoDayAs ${isVertical ? 'vertical' : 'horizontal'}`} ref={videoRef} controls>
              <source src={videoWorkday?.workday_video_path || 0} type='video/mp4'/>
            </video>
            :
            <div>
              <img src={Photo3} className='dayAsVideoPlaceholder'/>
            </div>
          }
          <div className='textDayAs'>Een werkdag als <span className='whiteSpaceWrap m-0'>{truncateDescription(vacancy.function_name, 50)}</span> bij TechDog Group</div>
        </div> */}
        <div className='questionsContact d-flex flex-row'>
          <div className='w-50 questionsContactText'>
            <div className='mb-5'>
              <p className='titleTextQuestionsContact'>Hey! Ik doe graag een kopje koffie met je.</p>
              <div className=' jobdesc mb-3'>
                <p>Hallo, ik ben <span className='whiteSpaceWrap'>{truncateDescription(vacancy?.employee, 35) || `een ${truncateDescription(vacancy.function_name, 35)} bij Techdog Group`}</span>. Ik kan mij voorstellen dat je nog met vragen zit, maar laten we het daar vooral eens bij ons op kantoor over hebben. Het gaat tenslotte om jou als mens.</p>
              </div>
              <p className='jobdesc'>Je kunt mij bereiken via het telefoonnummer en email adres</p>
            </div>
            <div className='d-flex flex-row'>
              <div className='me-3'>
                <IconButton sideIcon={"left"} icon={icoon} textButton={"+31 85 760 0729"} phone={"+31 85 760 0729"} />
              </div>
              <div className='mailButton'>
                <IconButton sideIcon={"left"} icon={mail} textButton={"sollicitatie@techdog.nl"} mail={"sollicitatie@techdog.nl"} />
              </div>
            </div>
          </div>
          <div className=' questionsContactImgParent' style={{ width: "60%" }}>
            <img className='px-4 questionsContactImg' src={vacancy.employee_image || Photo4}></img>
          </div>
        </div>
        {vacancy.employee_experiences_array?.length > 0 ?
          <div className='ourColleagues'>
            <div className='ourColleaguesImg'>
              <img src={Photo5} />
            </div>
            <div className='ourColleaguesWords'>
              <h1 className='fw-bold ourColleaguesTitle mb-4'>Onze collega's aan het woord:</h1>
              <div className='d-flex justify-content-between flex-column'>
                <div className='textsColleagues'>
                  {decodeJsonString(vacancy.employee_experiences_array)?.map((experience, key) => {
                    let experienceClass = 'textColleague';

                    if (experiencesCount <= 2) {
                      experienceClass = 'textColleagueOne';
                    }

                    return (
                      <div className='mb-4' key={key}>
                        <h5 style={{ fontSize: "20px" }} className={`fw-bold whiteSpaceWrap nameEmployee`}>{experience.full_name}</h5>
                        <p style={{ fontSize: "18px" }} className={`whiteSpaceWrap ${experienceClass}`}>{experience.experience_description}</p>
                      </div>
                    );
                  })}
                </div>
                <div className='iconButtonArrowRight'>
                  <IconButton sideIcon={"right"} icon={arrowRight} textButton={"Meer over onze cultuur"} disabled={true} />
                </div>
              </div>
            </div>
          </div>
          :
          ""
        }

        <div className='ourClients d-flex flex-row'>
          <div className='ourClientsText'>
            <div>
              <p className='titleTextOurClients'>Benieuwd naar een van onze opdrachtgevers?</p>
              <div className='subTextOurClients'>Neem dan eens een kijkje bij <span className='wordbreakAll'>{truncateDescription(vacancy.case_name, 35) || "één van onze opdrachtgevers"}</span>!</div>
              <div className='mb-5 ourClientsTextList'>
                {decodeJsonString(vacancy.case_points_array)?.map((casePoint, key) =>
                  <div key={key} className='d-flex flex-row my-3 '>
                    <div className='checkBackground'>
                      <img src={check} className='checkImg' alt="Check" />
                    </div>
                    <div className='bulletItem'>
                    <p style={{ fontSize: "20px" }}>{casePoint.title}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='ourClientsImgParent'>
            <img className='px-4 ourClientsImg' src={vacancy.case_upload || Photo6}></img>
          </div>
        </div>
        <div>
          <div className='aboutUs'>
            <h1 className='text-white proxima-nova-bold' style={{ fontSize: "45px", marginBottom: "40px" }}>Over TechDog Group</h1>
            <p className='aboutUsSubtitle' style={{ marginBottom: "40px" }}>Totale data oplossingen voor het MKB</p>
            <p className='jobdesc mb-5'>Je gaat aan de slag bij Sellabees, het online marketingbureau van TechDog Group. Een jonge en energieke organisatie waar we ondernemers helpen om écht te groeien. Dit doen we met creatieve campagnes, het verbeteren van online vindbaarheid én het bouwen van websites en webshops die converteren.
              Je komt terecht in een jong en enthousiast team waar we niet alleen hard werken voor mooie klanten, maar ook veel plezier maken. Je werkt samen met verschillende afdelingen, schakelt via korte lijnen en krijgt volop vrijheid in je werk.
              Of je nu al ervaring hebt of nog aan het begin van je carrière staat: bij Sellabees krijg je de kans om impact te maken,
              te leren en vooral met plezier te werken.
            </p>
            <div className='iconButtonArrowRight discoverUs mb-5'>
              <IconButton reverted={true} sideIcon={"right"} icon={arrowRight} textButton={"Meer over onze organisatie"} disabled={true} />
            </div>
            <div className='imageAboutUsParent'>
              <img className='imageAboutUs' src={Photo7} />
            </div>
          </div>
          <div className='smallFactsCompany'>
            <div className='d-flex flex-column mb-5 align-items-center w-25'>
              <div className='companyInfoCircle mb-3' style={{ backgroundColor: "#7db9e2" }}><img src={established} style={{ height: "85px" }} /></div>
              <h2 className='d-flex align-items-center mb-0 proxima-nova-bold text-center'>Opgericht in 2016</h2>
            </div>
            <div className='d-flex flex-column mb-5 align-items-center' style={{ width: "35%" }}>
              <div className='companyInfoCircle mb-3' style={{ backgroundColor: "#2283c5" }}><img src={Pin} style={{ height: "85px" }} /></div>
              <h2 className='d-flex align-items-center mb-0 proxima-nova-bold text-center'>Gevestigd in Hengelo</h2>
            </div>
            <div className='d-flex flex-column mb-5 align-items-center w-25'>
              <div className='companyInfoCircle mb-3' style={{ backgroundColor: "#176192" }}><img src={Team} style={{ height: "75px" }} /></div>
              <h2 className='d-flex align-items-center mb-0 proxima-nova-bold text-center'>23 collega's</h2>
            </div>
          </div>
        </div>
        <div className='contactForm d-flex flex-row justify-content-center'>
          {/* Tijdelijk uit tot er iets voor is (in css 'top' commented )*/}
          {/*
          <div style={{width: "40%"}} className='d-flex flex-column justify-content-center'>
            <div className='d-flex justify-content-center'>
              <img src={vacancy.employee_image || Photo4} className=' questionsContactImg mb-5'/>
            </div>
            <div className='textContactFormInfo'>
              <h2 className='wordbreakAll proxima-nova-bold mb-4'>{truncateDescription(vacancy?.employee, 35)}</h2>
              <div style={{fontSize: "20px"}} className=''>Hallo, ik ben <span className='m-0 wordWrapAll'>{truncateDescription(vacancy?.employee, 35) || `een ${truncateDescription(vacancy?.function_name, 35)} bij Techdog Group`}.</span> Ik kan mij voorstellen dat je nog met vragen zit, dus ik kom graag met je in contact</div>
            </div>
          </div> */}
          <div className='ContactFormInputs'>
            <div>
              <h1 className='fw-bold' style={{ fontSize: "35px" }}>Zullen we kennis maken?</h1>
              <p style={{ fontSize: "22px" }} className='mb-4'>Kom eens langs voor een kop koffie of stel gerust jouw vraag</p>
            </div>
            <form onSubmit={submitContactForm} className='mb-2'>
              <input className='contactInputs' type="text" placeholder='Voornaam' maxLength={100} required onChange={(e) => setFirstName(e.target.value)} />
              <input className='contactInputs' type="text" placeholder='Achternaam' maxLength={100} required onChange={(e) => setLastName(e.target.value)} />
              <input className='contactInputs' type="tel" placeholder='Telefoonnummer' maxLength={10} required onChange={(e) => setTelNum(e.target.value)} />
              <input className='contactInputs' type="email" placeholder='E-mailadres' required onChange={(e) => setEmail(e.target.value)} />
              <select className='contactInputs standardDropdownArrow pe-5' required onChange={(e) => setContactReason(e.target.value)}>
                <option default >{vacancy.function_name}</option>
                <option >Open sollicitatie</option>
              </select>
              <button className='submitButtonContact'>Versturen</button>
            </form>
            <p className='reactionText'>Binnen een werkdag neem ik contact met je op. Tot snel!</p>
          </div>
        </div>
      </div>
      <div className='footerHomepage'>
        <Footer />
      </div>
    </PageTemplate>
  );
}

export default HomePage;
