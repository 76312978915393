import './Footer.css';
import email from '../Images/email.png'
import phone from '../Images/Icon metro-phone.svg'
import pin from '../Images/pin.svg'
import clock from '../Images/clock-fullwhite.svg'
import logo from '../Images/logo tech dog_group_met payoff.svg'
import tiktok from '../Images/tik-tok.svg'
import instagram from '../Images/instagram.svg'
import facebook from '../Images/_x30_1._Facebook.svg'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='row row-cols-1 row-cols-md-2 row-cols-lg-4'>
                <div className='col techdog '>
                    <img src={logo} alt="logo" className='logo img-fluid' />
                    <p className="icon">
                        <img src={pin} alt="email" className='pin'/>
                        Oude Boekeloseweg 9, 7553DS Hengelo
                    </p>
                    <p className="icon">
                        <img src={phone} alt="email" />
                        +31 (0) 85 760 07 29
                    </p>
                    <p className="icon">
                        <img src={email} alt="email" />
                        info@techdog.nl
                    </p>
                    <p className="icon">
                        <img src={clock} alt="email" />
                        Open op werkdagen van:
                    </p>
                    <p className='officeHours'>9:00 tot 17:00 uur</p>
                </div>
                <div className='col products'>
                    <p className='header'>PRODUCTEN</p>
                    <p>Digitale basis</p>
                    <p>Payments</p>
                    <p>Traffic generatie</p>
                    <p>Bedrijfssytemen</p>
                </div>
                <div className='col about'>
                    <p className='header'>OVER ONS</p>
                    <p>Over TechDog</p>
                    <p>Online portaal</p>
                    <p>Veelgestelde vragen</p>
                    <p>Algemene voorwaarden</p>
                    <p>Privacy verklaring</p>
                    <p>Disclaimer</p>
                </div>
                <div className='col socials'>
                    <p className='header'>SOCIAL MEDIA</p>
                    <p className="icon">
                        <img src={facebook} alt="facebook" className='fb' />
                        Facebook
                    </p>
                    <p className="icon">
                        <img src={instagram} alt="instagram" />
                        Instagram
                    </p>
                    <p className="icon">
                        <img src={tiktok} alt="tiktok" />
                        Tiktok
                    </p>
                </div>
                </div>
            </div>
            <div className="footerBottom d-flex ">
                <div className='bottomContent d-flex align-items-center justify-content-end'>
                <p>   
                    © {currentYear} TechDog /
                    <a href="#privacy">Privacybeleid</a> |
                    <a href="#algemene-voorwaarden">Algemene voorwaarden</a> |
                    <a href="#cookiebeleid">Cookiebeleid</a> |
                    <a href="#disclaimer">Disclaimer</a>
                </p>
                </div>
            </div>
        </div>
    );
}

export default Footer;