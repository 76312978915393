import React, { useEffect, useState } from "react";
import PageTemplate from "../Template/PageTemplate";
import Footer from "../Footer/Footer";
import IconButton from "../MainComponents/IconButton";
import "../HomePage/HomePage.css";
import "./Vacatures.css";
import Photo1 from "../Images/Rectangle 22.png";
import filter from "../Images/sliders-solid.svg";
import werkenbij from "../Api/werkenbij";
import { useNavigate, useLocation } from "react-router";
import Cookies from "universal-cookie";
import VacanciesCard from "../MainComponents/vacanciesCard";
import ReactPaginate from "react-paginate";
import LoadingScreenUsers from "../LoadingScreen/LoadingScreenUsers";
import { use } from "react";


const Vacatures = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const [allVacatures, setAllVacatures] = useState([]);
  const [vacatures, setVacatures] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [burgerMenuVisible, setBurgerMenuVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page_size: 9,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table

  // Parse the current page from the URL query parameters
  const query = new URLSearchParams(location.search);
  const initialPage = parseInt(query.get("page")) || 1;
  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')      // Vervang spaties door -
      .replace(/[^\w\-]+/g, '')  // Verwijder niet-alfanumerieke tekens
      .replace(/\-\-+/g, '-');   // Vervang meerdere koppelteken door één
  };

  const fetchAll = async () => {
    try {
      setLoadingPage(true);
      const [fetchVacatures, fetchDepartments, fetchJobtypes] = await Promise.all([
        werkenbij.get(`sp/vacancypageoverview?page=${pagination.page}&page_size=${pagination.page_size}&offer_status=0`),
        werkenbij.get("/info/departments"),
        werkenbij.get("/info/jobtypes"),
      ]);

      setVacatures(fetchVacatures.data.data);
      setAllVacatures(fetchVacatures.data.data);
      setDepartments(fetchDepartments.data);
      setJobTypes(fetchJobtypes.data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        max_items: fetchVacatures.data.total,
        begin_of_page: fetchVacatures.data.from,
        end_of_page: fetchVacatures.data.to,
      }));
      setLoadingPage(false);
    } catch (error) {

      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: initialPage,
    }));
  }, [initialPage]);

  useEffect(() => {
    fetchAll();
  }, [pagination.page]);

  const fetchFilteredVacancy = async () => {
    try {

      const noSearchArray = [
        "function_description",
        "job_offer_header_image",
        "job_offer_id",
        "offer_status"
      ];

      const noSearch = encodeURIComponent(JSON.stringify(noSearchArray));

      const url = `sp/vacancypageoverview?` +
        (selectedDepartments.length > 0 ? `department=${selectedDepartments.join(",")}&` : "") +
        (selectedJobTypes.length > 0 ? `job_type=${selectedJobTypes.join(",")}&` : "") +
        (debouncedSearchTerm.length !== 0 ? `search=${debouncedSearchTerm}&` : "") +
        `noSearch=${noSearch}&` +
        `page=${pagination.page}&page_size=${pagination.page_size}&offer_status=0`;

      setLoading(true);
      const [fetchVacatures] = await Promise.all([
        werkenbij.get(url)
      ]);

      setVacatures(fetchVacatures.data.data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        max_items: fetchVacatures.data.total,
        begin_of_page: fetchVacatures.data.from,
        end_of_page: fetchVacatures.data.to,
      }));
      setLoading(false);
    } catch (error) {

      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    fetchFilteredVacancy();
  }, [selectedDepartments, debouncedSearchTerm, selectedJobTypes, pagination.page]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePageClick = (data) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: data.selected + 1,
    }));
    navigate(`?page=${data.selected + 1}`);
  };

  const handleCheckboxChange = (setSelectedFunction, value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
    }));
    setSelectedFunction((prevSelected) =>
      prevSelected.includes(value) ? prevSelected.filter((item) => item !== value) : [...prevSelected, value]
    );
  };

  if (loadingPage) {
    return <LoadingScreenUsers>laden</LoadingScreenUsers>;
  }

  const jobTypesWithVacancies = jobTypes.filter((jobType) =>
    allVacatures.some((vacature) => vacature.job_type === jobType.title)
  );
  const departmentsWithVacancies = departments.filter((department) =>
    allVacatures.some((vacature) => vacature.department === department.title)
  );
  
  return (
    <PageTemplate>
      <div>
        <div className="imgVacatures">
          <img src={Photo1} className="imgheaderVacatures" alt="Online Marketing" />
        </div>
        <div className="w-100 d-flex justify-content-center vacanciesParent">
          <div className="vacancies d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center titlePageParent">
              <p className="PageTitle mb-0">VACATURES</p>
              <p style={{ fontSize: "25px" }} className="fw-semibold subtitleVacancie">
                Vind hier je volgende baan
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row vacanciesList">
          {width > 1030 && (
            <div className="w-25 d-flex flex-column px-4 searchFilters">
              <div>
                <label className="filterTitle mb-3">Zoek een vacature</label>
                <input
                  type="text"
                  className="form-control searchInputVacancies mb-4 rounded-pill"
                  placeholder="Welke vacature zoek je?"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div>
                <label className="filterTitle mb-3">Werkgebied</label>
                <div className="filter-sectionVacancie">
                  {departmentsWithVacancies.map((department) => (
                    <div key={department.department_id} className="form-check custom-checkbox ps-0">
                      <input
                        className="form-check-input custom-check-input"
                        type="checkbox"
                        value={department.title}
                        id={`dept-${department.department_id}`}
                        onChange={() => handleCheckboxChange(setSelectedDepartments, department.title)}
                        checked={selectedDepartments.includes(department.title)}
                      />
                      <label
                        className="form-check-label custom-check-label cursorPointer my-3 d-flex align-items-center"
                        htmlFor={`dept-${department.department_id}`}
                      >
                        {department.title}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="dividerSearchItems my-4" />
              <div>
                <label className="filterTitle mb-3">Type baan</label>
                <div className="filter-sectionVacancie">
                  {jobTypesWithVacancies.map((jobType) => (
                    <div key={jobType.job_type_id} className="form-check custom-checkbox ps-0">
                      <input
                        className="form-check-input custom-check-input"
                        type="checkbox"
                        value={jobType.title}
                        id={`jobtype-${jobType.job_type_id}`}
                        onChange={() => handleCheckboxChange(setSelectedJobTypes, jobType.title)}
                        checked={selectedJobTypes.includes(jobType.title)}
                      />
                      <label
                        className="form-check-label custom-check-label cursorPointer my-3 d-flex align-items-center"
                        htmlFor={`jobtype-${jobType.job_type_id}`}
                      >
                        {jobType.title}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="dividerSearchItems my-4" />
            </div>
          )}
          {loading ? (
            <div className="w-75 jobOffersGridParent d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="d-flex flex-column w-75 jobOffersGridParent">
              {vacatures.length < 1 ? (
                <div className="text-center fw-semibold fs-4">Geen resultaten gevonden</div>
              ) : (
                <div className="vacanciesGrid">
                  {vacatures.map((vacature, key) => {
                    return (
                      <div key={key} className="cardContainer cursorPointer" onClick={() =>
                        navigate(`/vacatureInfo/${slugify(vacature.function_name)}`, {
                          state: { job_offer_id: vacature.job_offer_id },
                          replace: true
                        })
                      }
                      >
                        <VacanciesCard
                          key={vacature.job_offer_id}
                          img={vacature.job_offer_header_image}
                          functionName={vacature.function_name}
                          hours={vacature.work_hours}
                          navVacancie={vacature.job_offer_id}
                          department={vacature.department ? vacature.department : "Unknown Department"}
                          description={vacature.function_description}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {pagination.max_items > 0 && (
                <div>
                  <ReactPaginate
                    previousLabel={""}
                    nextLabel={""}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(pagination.max_items / pagination.page_size)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    forcePage={pagination.page - 1}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeLinkClassName={"active-link"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {width <= 1030 && (
        <>
          <div className="filterbutton">
            <button className="filterButtonStyle" onClick={() => setBurgerMenuVisible(!burgerMenuVisible)}>
              <img src={filter} className="me-2" style={{ width: "20px", height: "20px" }} />
              Filter resultaten
            </button>
          </div>
          <div className={`burgerMenu ${burgerMenuVisible ? "open" : ""}`}>
            <button className="closeButton" onClick={() => setBurgerMenuVisible(false)}>
              ×
            </button>
            <div>
              <label className="filterTitle mb-3">Zoek een vacature</label>
              <input
                type="text"
                className="form-control searchInputVacancies mb-4 rounded-pill"
                placeholder="Welke vacature zoek je?"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div>
              <label className="filterTitle mb-3">Werkgebied</label>
              <div className="filter-sectionVacancie">
                {departmentsWithVacancies.map((department) => (
                  <div key={department.department_id} className="form-check custom-checkbox ps-0">
                    <input
                      className="form-check-input custom-check-input"
                      type="checkbox"
                      value={department.title}
                      id={`burger-dept-${department.department_id}`}
                      onChange={() => handleCheckboxChange(setSelectedDepartments, department.title)}
                      checked={selectedDepartments.includes(department.title)}
                    />
                    <label
                      className="form-check-label custom-check-label cursorPointer my-3 d-flex align-items-center"
                      htmlFor={`burger-dept-${department.department_id}`}
                    >
                      {department.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="dividerSearchItems my-4" />
            <div>
              <label className="filterTitle mb-3">Job Type</label>
              <div className="filter-sectionVacancie">
                {jobTypesWithVacancies.map((jobType) => (
                  <div key={jobType.job_type_id} className="form-check custom-checkbox ps-0">
                    <input
                      className="form-check-input custom-check-input"
                      type="checkbox"
                      value={jobType.title}
                      id={`burger-jobtype-${jobType.job_type_id}`}
                      onChange={() => handleCheckboxChange(setSelectedJobTypes, jobType.title)}
                      checked={selectedJobTypes.includes(jobType.title)}
                    />
                    <label
                      className="form-check-label custom-check-label cursorPointer my-3 d-flex align-items-center"
                      htmlFor={`burger-jobtype-${jobType.job_type_id}`}
                    >
                      {jobType.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="dividerSearchItems my-4" />
            <button className="filterButtonStyleResult" onClick={() => setBurgerMenuVisible(false)}>
              <img src={filter} className="me-2" style={{ width: "20px", height: "20px" }} />
              Filter resultaten
            </button>
          </div>
        </>
      )}
      <div className="footerVacancies">
        <Footer />
      </div>
    </PageTemplate>
  );
};

export default Vacatures;
